"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var appUtil_1 = require("@/function/utils/appUtil");
exports.default = (0, vue_1.defineComponent)({
    name: 'DepPan',
    data: function () {
        return {
            openLink: appUtil_1.openLink
        };
    }
});
