"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var systemUtil_1 = require("@/function/utils/systemUtil");
exports.default = (0, vue_1.defineComponent)({
    name: 'UpdatePan',
    props: ['version', 'user', 'message', 'from'],
    data: function () {
        return {
            getTrueLang: systemUtil_1.getTrueLang,
            info: {
                title: '',
                content: []
            }
        };
    },
    mounted: function () {
        var updateInfo = this.message.split('\n');
        this.info.title = updateInfo[0];
        for (var i = 1; i < updateInfo.length; i++) {
            var text = updateInfo[i];
            if (text.startsWith(':')) {
                var end = text.substring(1).indexOf(':');
                var name = text.substring(0, end + 2);
                var emj = (0, systemUtil_1.gitmojiToEmoji)(name);
                if (emj != undefined) {
                    text = text.replace(name, emj);
                }
            }
            this.info.content.push(text);
        }
    }
});
