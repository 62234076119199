"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var systemUtil_1 = require("@/function/utils/systemUtil");
var connect_1 = require("@/function/connect");
var msg_1 = require("@/function/msg");
exports.default = (0, vue_1.defineComponent)({
    name: 'FileBody',
    props: ['item', 'chat', 'parent'],
    data: function () {
        return {
            trueLang: (0, systemUtil_1.getTrueLang)(),
            getSize: systemUtil_1.getSizeFromBytes,
            toHtml: systemUtil_1.htmlDecodeByRegExp
        };
    },
    methods: {
        /**
         * 下载文件（获取文件下载地址并下载）
         */
        getFile: function (item) {
            if (this.parent === undefined) {
                connect_1.Connector.send('get_file_url', {
                    id: msg_1.runtimeData.chatInfo.show.id,
                    message_id: msg_1.runtimeData.messageList[0].message_id,
                    fid: item.id
                }, 'downloadGroupFile_' + item.id);
            }
            else {
                // 对于文件夹里的文件需要再找一次 ……
                connect_1.Connector.send('http_proxy', {
                    id: msg_1.runtimeData.chatInfo.show.id,
                    message_id: msg_1.runtimeData.messageList[0].message_id,
                    fid: item.id
                }, 'downloadGroupFile_' + this.parent + '_' + item.id);
            }
            // PS：在发起下载后就要将百分比设置好 …… 因为下载部分不一定立刻会开始
            // 这时候如果用户疑惑为什么点了没反应会多次操作的（用户竟是我自己）
            item.downloadingPercentage = 0;
        },
        /**
         * 加载子文件夹
         */
        loadFileDir: function (id, type) {
            if (type === 2 && this.item.sub_list === undefined) {
                // 加载群文件列表
                var url = "https://pan.qun.qq.com/cgi-bin/group_file/get_file_list?gc=".concat(this.chat.show.id, "&bkn=").concat(msg_1.runtimeData.loginInfo.bkn, "&start_index=0&cnt=30&filter_code=0&folder_id=").concat(id, "&show_onlinedoc_folder=0");
                connect_1.Connector.send('http_proxy', { 'url': url }, 'getGroupDirFiles_' + id);
            }
        }
    }
});
