import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, renderList as _renderList, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "opt-page" }
const _hoisted_2 = { class: "ss-card account-info" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ss-card" }
const _hoisted_5 = { class: "opt-item" }
const _hoisted_6 = {
  key: 0,
  class: "opt-item"
}
const _hoisted_7 = {
  key: 1,
  class: "ss-card account-not-login"
}
const _hoisted_8 = {
  key: 2,
  class: "ss-card"
}
const _hoisted_9 = { class: "l10n-info" }
const _hoisted_10 = { class: "bot-info" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (Object.keys($data.runtimeData.loginInfo).length > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: 'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + $data.runtimeData.loginInfo.uin
            }, null, 8, _hoisted_3),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString($data.runtimeData.loginInfo.nickname), 1),
                _createElementVNode("span", null, _toDisplayString($data.runtimeData.loginInfo.uin), 1)
              ]),
              _createElementVNode("span", null, _toDisplayString($data.runtimeData.loginInfo.info && Object.keys($data.runtimeData.loginInfo.info).length > 0 ?
                            $data.runtimeData.loginInfo.info.lnick : ''), 1)
            ]),
            _createVNode(_component_font_awesome_icon, {
              onClick: $options.exitConnect,
              icon: ['fas', 'right-from-bracket']
            }, null, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_account_config')), 1),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'address-card'] }),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_account_nick')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_account_nick_tip')), 1)
              ]),
              _withDirectives(_createElementVNode("input", {
                class: "ss-input",
                style: {"width":"150px"},
                type: "text",
                onKeyup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.setNick && $options.setNick(...args))),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.runtimeData.loginInfo.nickname) = $event))
              }, null, 544), [
                [_vModelText, $data.runtimeData.loginInfo.nickname]
              ])
            ]),
            ($data.runtimeData.loginInfo.info && Object.keys($data.runtimeData.loginInfo.info).length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'pen'] }),
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_account_lnick')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_account_lnick_tip')), 1)
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    class: "ss-input",
                    style: {"width":"150px"},
                    type: "text",
                    onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.setLNick && $options.setLNick(...args))),
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.runtimeData.loginInfo.info.lnick) = $event))
                  }, null, 544), [
                    [_vModelText, $data.runtimeData.loginInfo.info.lnick]
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'fish'] }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_account_notlogin')), 1),
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.goLogin && $options.goLogin(...args))),
            class: "ss-button"
          }, _toDisplayString(_ctx.$t('option_account_gologin')), 1)
        ])),
    (Object.keys($data.runtimeData.botInfo).length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_account_bot')), 1),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'robot'] }),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, [
                _createTextVNode(_toDisplayString($data.runtimeData.botInfo.app_name), 1),
                _createElementVNode("a", null, _toDisplayString($data.runtimeData.botInfo.app_version !== undefined ?
                            $data.runtimeData.botInfo.app_version : $data.runtimeData.botInfo.version), 1)
              ]),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_account_bot_tip')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys($data.runtimeData.botInfo), (key) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'botinfo-' + key
              }, [
                (key !== 'app_name' && key !== 'app_version' && key !== 'version')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('botinfo_' + key) + ': '), 1),
                      ((typeof $data.runtimeData.botInfo[key]) !== 'object')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString($options.paseBotInfo(key, $data.runtimeData.botInfo[key])), 1))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(Object.keys($data.runtimeData.botInfo[key]), (item) => {
                            return _withDirectives((_openBlock(), _createElementBlock("span", {
                              key: 'botinfo-' + key + item
                            }, _toDisplayString((typeof $data.runtimeData.botInfo[key][item] == 'number' ? 
                            _ctx.$t('botinfo_' + item, $data.runtimeData.botInfo[key][item]) : _ctx.$t('botinfo_' + item))
                             + ': ' + $options.paseBotInfo(item, $data.runtimeData.botInfo[key][item])), 1)), [
                              [_vShow, (typeof $data.runtimeData.botInfo[key][item]) !== 'object']
                            ])
                          }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}